import React from 'react'
import Decor from "../assets/Decor.png";
import Img1 from "../assets/1.png";
import Img2 from "../assets/2.png";
import Img3 from "../assets/3.png";
import Img4 from "../assets/4.png";
import Img5 from "../assets/5.png";
import Img6 from "../assets/6.png";
import Img7 from "../assets/7.png";
import Img8 from "../assets/8.png";
import Img9 from "../assets/9.png";
import Img10 from "../assets/10.png";
import Img11 from "../assets/11.png";
import Img12 from "../assets/12.png";

export default function Header({myRef}) {
    return (
        <div ref={myRef} className={"Header"}>
            <h1>About Love<br/>Llamas NFT</h1>
            <button onClick={()=>window.open("https://discord.gg/EqcHAyEG")}>
                <h2>Join our Discord</h2>
            </button>
            <img className='Decor' src={Decor}/>
            <div className='Flare1'/>
            <div className='Flare2'/>
            <div className='Flare3'/>
            <div class="slider1">
            <div className='CaroselCon'>
                <img className='CaroselImg' src={Img1} />
                <img className='CaroselImg' src={Img2} />
                <img className='CaroselImg' src={Img3} />
                <img className='CaroselImg' src={Img4} />
                <img className='CaroselImg' src={Img5} />
                <img className='CaroselImg' src={Img6} />
                <img className='CaroselImg' src={Img7} />
                <img className='CaroselImg' src={Img8} />
                <img className='CaroselImg' src={Img9} />
                <img className='CaroselImg' src={Img10} />
                <img className='CaroselImg' src={Img11} />
                <img className='CaroselImg' src={Img12} />
            </div>
            </div>
            <div class="slider">
            <div className='CaroselCon1'>
                <img className='CaroselImg' src={Img7} />
                <img className='CaroselImg' src={Img8} />
                <img className='CaroselImg' src={Img9} />
                <img className='CaroselImg' src={Img10} />
                <img className='CaroselImg' src={Img11} />
                <img className='CaroselImg' src={Img12} />
                <img className='CaroselImg' src={Img1} />
                <img className='CaroselImg' src={Img2} />
                <img className='CaroselImg' src={Img3} />
                <img className='CaroselImg' src={Img4} />
                <img className='CaroselImg' src={Img5} />
                <img className='CaroselImg' src={Img6} />
            </div>
            </div>
        </div>
    )
}
