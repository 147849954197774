import React from 'react'
import { TiSocialFacebookCircular } from "react-icons/ti";
import { RiFacebookCircleLine, RiTwitterLine, RiDiscordLine } from "react-icons/ri";

export default function Footer() {
  return (
    <div className='Footer'>
      <div style={{display:"flex"}}>
        {/* <div className='SocialIcoCon' onClick={()=>window.open("https://twitter.com/LoveLLamasFam")}>
        <RiFacebookCircleLine size={25} color={"#2B1D42"}/>
        </div> */}
        <div className='SocialIcoCon1' onClick={()=>window.open("https://twitter.com/LoveLlamasFam")}>
        <RiTwitterLine size={25} color={"#fff"}/>
        </div>
        <div className='SocialIcoCon1' onClick={()=>window.open("https://discord.gg/EqcHAyEG")}>
        <RiDiscordLine size={25} color={"#fff"}/>
        </div>
        </div>
        <div className='FootFlex'/>
        <div style={{display:"flex", alignItems:"center", marginTop:20}}>
            <div className='Logo'/>
            <h1>love<br/>llamas</h1>
            </div>
        <div className='FootFlex'/>
        <h2>©️ 2021-2022 LOVE LLAMASº</h2>
    </div>
  )
}
