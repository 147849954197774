import React,{useState, useEffect} from "react";
import "react-vertical-timeline-component/style.min.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

export default function RoadMap({myRef}) {
  const [show, setShow] = useState(false);
  const [scrollVal, setScrollVal] = useState(0);

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])

  function onScrollRight(val, max){
    if(scrollVal == max){
      setScrollVal(max)
    }else{
      setScrollVal(val)
    }
  }

  
  function onScrollLeft(val){
    if(scrollVal == 0){
      setScrollVal(0)
    }else{
      setScrollVal(val)
    }
  }

  return (
    <div ref={myRef} className={"RoadMap"}>
      <h1>ROADMAP</h1>
      {/* <h4 style={{width: 723,}}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</h4>
      <h4 style={{width: 546,}}>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</h4> */}
      <div className={ show? "RoadCon RoadShow" : "RoadCon"}>
      <div className="RoadCard" style={{marginLeft:"15%"}}>
      <div className="Star"/>
      <h2>Milestone #1</h2>
      <p>In 2022, we'll be dropping our Love Llamas NFT collection. It will be a limited-release collection of NFTs that we know you're going to love!</p>
      </div>
      <div className="RoadCard">
      <div className="Star1"/>
      <h2>Milestone #2</h2>
      <p>In May, we'll launch our own DAO, giving voting power to Llama NFT holders.</p>
      </div>
      <div className="RoadCard">
      <div className="Star1"/>
      <h2>Milestone #3</h2>
      <p>In June, we'll be launching a job board for web3 gigs, so you can get paid for doing what you love even more quickly than before.</p>
      </div>
      <div className="RoadCard">
      <div className="Star1"/>
      <h2>Milestone #4</h2>
      <p>In July, we're going to tap into the metaverse and open up our NFT academies — because we know how much you all love a good quest!</p>
      </div>
      <div className="RoadCard">
      <div className="Star1"/>
      <h2>Milestone #5</h2>
      <p>In Aug, we will be launching a certification tool—a new NFT learning experience that you're going to want to check out!</p>
      </div>
      </div>
      <div className={"RoadCon1"} style={{paddingRight:scrollVal}}>
      <div className="RoadCarosel">
      <div className="RoadCard" style={{marginLeft: windowDimenion.winWidth < 500? "5%" : "10%"}}>
      <div className="Star"/>
      <h2>Milestone #1</h2>
      <p>In 2022, we'll be dropping our Love Llamas NFT collection. It will be a limited-release collection of NFTs that we know you're going to love!</p>
      </div>
      <div className="RoadCard">
      <div className="Star1"/>
      <h2>Milestone #2</h2>
      <p>In May, we'll launch our own DAO, giving voting power to Llama NFT holders.</p>
      </div>
      <div className="RoadCard">
      <div className="Star1"/>
      <h2>Milestone #3</h2>
      <p>In June, we'll be launching a job board for web3 gigs, so you can get paid for doing what you love even more quickly than before.</p>
      </div>
      <div className="RoadCard">
      <div className="Star1"/>
      <h2>Milestone #4</h2>
      <p>In July, we're going to tap into the metaverse and open up our NFT academies — because we know how much you all love a good quest!</p>
      </div>
      <div className="RoadCard">
      <div className="Star1"/>
      <h2>Milestone #5</h2>
      <p>In Aug, we will be launching a certification tool—a new NFT learning experience that you're going to want to check out!</p>
      </div>
      </div>
      </div>
      <div className="RoadLine"/>
      {show?
      <div className="RoadArrowCon1" onClick={()=>setShow(false)}>
        <FaArrowLeft size={20} color={"#fff"}/>
      </div>
      :
      <div className="RoadArrowCon" onClick={()=>setShow(true)}>
        <FaArrowRight size={20} color={"#fff"}/>
      </div>
      }
      {scrollVal == 0?
      null
      :
      <div className="RoadArrowCon3" onClick={()=>onScrollLeft(scrollVal-200)}>
        <FaArrowLeft size={20} color={"#fff"}/>
      </div>
      }
      <div className="RoadArrowCon2" onClick={()=>onScrollRight(scrollVal+200, windowDimenion.winWidth > 650? 2000 : windowDimenion.winWidth > 300? 2600 : 2200 )}>
        <FaArrowRight size={20} color={"#fff"}/>
      </div>
    </div>
  );
}
