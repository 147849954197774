import React, { useEffect, useState, useRef } from "react";
import { BsPlayCircle } from "react-icons/bs";
import Mint from "../assets/mint.png";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import { Link } from "react-router-dom";

export default function Mining() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 4) {
      newMintAmount = 4;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  },[]);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className={"Mining"}>
      <div className={"MintDiv"}>
        <div className={"MintLeft"}>
          <button className={"MintBtn1"}>Minting starts soon</button>
           {/* <h2 className={"HeroNumText"}>
            100 / {CONFIG.MAX_SUPPLY}
          </h2>
          <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:40}}>
              <button className={"MintBtn2"} onClick={(e) => {
                  e.preventDefault();
                  decrementMintAmount();
              }}>
                  -
              </button>
              <h4>{mintAmount}</h4>
              <button className={"MintBtn2"} onClick={(e) => {
                  e.preventDefault();
                  incrementMintAmount();
              }}>
                  +
              </button>
          </div>
          {blockchain.account === "" || blockchain.smartContract === null ? (
            <>
              <button className={"MintBtn1"} onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(connect());
                                    getData();
                                }}>Connect Wallet</button>
              {blockchain.errorMsg !== "" ? (
                <>
                  <h3>{blockchain.errorMsg}</h3>
                </>
              ) : null}
            </>
          ) : <>
          <h3>{feedback}</h3>
          <div style={{display:"flex", alignItems:"center", marginBottom:20}}>
              <button className={"MintBtn2"} onClick={(e) => {
                  e.preventDefault();
                  decrementMintAmount();
              }}>
                  -
              </button>
              <h2 style={{marginLeft:20, marginRight:20 }}>{mintAmount}</h2>
              <button className={"MintBtn2"} onClick={(e) => {
                  e.preventDefault();
                  incrementMintAmount();
              }}>
                  +
              </button>
          </div>
          <button className={"MintBtn1"} onClick={(e) => {
              e.preventDefault();
              claimNFTs();
              getData();
          }} >
              {claimingNft ? "MINTING" : "MINT"}
          </button>
      </>
      } */}
        </div>
      </div>
        <img style={{ borderRadius: 10 }} src={Mint} />
    </div>
  );
}
