import React, { useState } from 'react';
import "../style.css"
import { RiMenu3Fill, RiCloseLine } from "react-icons/ri";
import { RiFacebookCircleLine, RiTwitterLine, RiDiscordLine } from "react-icons/ri";

export default function NavBar({scrollTo}) {
    const [show, setShow] = useState(false)

  const ClickedMenu = (t) => {
    setShow(false)
    scrollTo(t)
  }
    return (
      <>
        <div className={"NavBar"}>
          <div style={{display:"flex", alignItems:"center", userSelect:"none", cursor:"pointer"}} onClick={()=>window.open("https://lovellamas.io")}>
            <div className='Logo'/>
            <h1>love<br/>llamas</h1>
            </div>
            <ul>
                <li><a style={{color:"#fff", fontWeight:"600"}} onClick={() => ClickedMenu("h")}>Home</a></li>
                {/* <li><a onClick={() => ClickedMenu("a")}>Works</a></li>
                <li><a onClick={() => ClickedMenu("a")}>About</a></li> */}
                <li><a onClick={() => ClickedMenu("r")}>Roadmap</a></li>
                <li><a onClick={() => ClickedMenu("a")}>Utilities</a></li>
                <li><a onClick={() => ClickedMenu("t")}>Team</a></li>
                <li><a onClick={() => ClickedMenu("f")}>FAQs</a></li>
            </ul>
            <div className='FlexCon'/>
            <div style={{display:"flex"}}>
        <div className='SocialIcoCon2' onClick={()=>window.open("https://twitter.com/LoveLlamasFam")}>
        <RiTwitterLine size={25} color={"#fff"}/>
        </div>
        <div className='SocialIcoCon2' onClick={()=>window.open("https://discord.gg/EqcHAyEG")}>
        <RiDiscordLine size={25} color={"#fff"}/>
        </div>
        </div>
            <button>
              <h2>Minting Soon</h2>
            </button>
            <div className={"MenuIconCon"}>
            <RiMenu3Fill size={30} color={"#fff"} onClick={() => setShow(true)} />
            </div>
        </div>
        <div className={show? "MenuCon Show" : "MenuCon"}>
        <RiCloseLine style={{position:"absolute", right:22, top:40}} size={30} color={"#281d42"} onClick={() => setShow(false)} />
        <ul>
                <li><a onClick={() => ClickedMenu("h")}>Home</a></li>
                <li><a onClick={() => ClickedMenu("r")}>Roadmap</a></li>
                <li><a onClick={() => ClickedMenu("a")}>Utilities</a></li>
                <li><a onClick={() => ClickedMenu("t")}>Team</a></li>
                <li><a onClick={() => ClickedMenu("f")}>FAQs</a></li>
            </ul>
            <div style={{display:"flex"}}>
        <div className='SocialIcoCon3' onClick={()=>window.open("https://twitter.com/LoveLlamasFam")}>
        <RiTwitterLine size={25} color={"#281d42"}/>
        </div>
        <div className='SocialIcoCon3' onClick={()=>window.open("https://discord.gg/EqcHAyEG")}>
        <RiDiscordLine size={25} color={"#281d42"}/>
        </div>
        </div>
        </div>
        </>
    )
}
