import React from 'react'
import Team1 from "../assets/team1.png";
import Team2 from "../assets/team2.png";

export default function Team({myRef}) {
  return (
    <div className='Team' ref={myRef}>
        <h1>OUR TEAM</h1>
        {/* <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p> */}
        <div className='TeamCon'>
        <div className='TeamCard'>
        <img src={Team1}/>
        <h2>Lucky26: Founder</h2>
        <p>Lucky26 loves diving deep into web3 and crypto projects when he's not building
relationships. As a co-founder of Love Llamas, Lucky26 is responsible for assisting
with project direction. He's been working in project management for six years now,
and he's excited to finally be able to combine his passion for blockchain and
cryptocurrency with his love of the Llamas community</p>
        </div>
        <div className='TeamCard'>
        <img src={Team2}/>
        <h2>Clueless Nidget: Core Dev</h2>
        <p>Clueless Nidget is working on development work for Love Llamas. Clueless Nidget
has been working in the software industry for five years, developing and managing
websites, e-commerce sites, and now NFT collections!</p>
        </div>
        <div className='TeamCard'>
        <img src={Team1}/>
        <h2>Marketing Team: Partner</h2>
        <p>Marketing Team is working on development, marketing, and community services for
Love Llamas. Marketing Team has been working in the crypto industry since 2021,
developing and testing smart contracts, launching main and minting sites,
marketing, and providing engagement solutions for both creators and teams.</p>
        </div>
    </div>
    </div>
  )
}
