import React,{useState} from 'react'
import '../style.css';

export default function FAQ({myRef}) {
    const [open, setOpen] = useState(null);
    const onOpen=(val)=>{
        if(val == open){
            setOpen(null)
        }else{
            setOpen(val)
        }
    }
    return (
        <div ref={myRef} className={"FAQ"}>
            <h1>FAQ</h1>
            <div className={"FAQCon"}>
                <div className='FAQQuCon' onClick={()=>onOpen(0)}>
                    <h2>What are Love Llamas NFTs?</h2>
                    <h2 style={{color:"rgba(255,255,255,0.8)"}}>+</h2>
                </div>
                <div className={open == 0? 'FAQAnCon FAQShow':'FAQAnCon'}>
                <h2>Love Llama non-fungible tokens (or NFTs) are digital collectibles based on the
                ERC721 token standard; you can trade on the Ethereum blockchain. They're also a
                fun way to send some love to your friends, family, and loved ones!</h2>
                </div>
                <div className='FAQQuCon' onClick={()=>onOpen(1)}>
                    <h2>How do I get a Love Llama NFT?</h2>
                    <h2 style={{color:"rgba(255,255,255,0.8)"}}>+</h2>
                </div>
                <div className={open == 1? 'FAQAnCon FAQShow':'FAQAnCon'}>
                <h2>You can buy one directly from us at our site or go to one of the many open markets
                where other users sell them.
                </h2>
                </div>
                <div className='FAQQuCon' onClick={()=>onOpen(2)}>
                    <h2>How much do they cost?</h2>
                    <h2 style={{color:"rgba(255,255,255,0.8)"}}>+</h2>
                </div>
                <div className={open == 2? 'FAQAnCon FAQShow':'FAQAnCon'}>
                <h2>Prices vary depending on how famous each Love Llama is. You can take a look at all
                the available designs here.
                </h2>
                </div>
                <div className='FAQQuCon' onClick={()=>onOpen(3)}>
                    <h2>How do I get in touch with the Love Llamas team?</h2>
                    <h2 style={{color:"rgba(255,255,255,0.8)"}}>+</h2>
                </div>
                <div className={open == 3? 'FAQAnCon FAQShow':'FAQAnCon'}>
                <h2>We can be reached on our <u style={{cursor:"pointer"}} onClick={()=>window.open("https://discord.gg/G3KPV5Q5")}>Discord</u>, or on Twitter <u style={{cursor:"pointer"}} onClick={()=>window.open("https://twitter.com/LoveLlamasFam")}>@lovellamasfam</u>
                </h2>
                </div>
            </div>
        </div>
    )
}