import React from 'react'
import Utility from "../assets/utilities.png";
import "../style.css"

export default function Utilities({myRef}) {
  return (
    <div className='Utilities' ref={myRef}>
        <img src={Utility}/>
        <div className='UtilRight'>
        <h1>UTILITIES</h1>
        <div className='UtilCon'>
        <div className="Star"/>
        <div className='UtilTexCon'>
        <h2>NFT holder</h2>
        <p>As a member of the Love Llamas family, you have access to a diverse array of tools and services designed to help you maximize your NFT holdings.</p>
        </div>
        </div>
        <div className='UtilCon'>
        <div className="Star"/>
        <div className='UtilTexCon'>
        <h2>DAO member</h2>
        <p>First, there's the Love Llamas DAO—a Decentralized Autonomous Organization in
        which each Llama holds gives its holder one vote on matters that come before it.
        Inside the DAO, the community will decide which NFT collections to acquire and
        which initiatives to pursue.
        </p>
        </div>
        </div>
        <div className='UtilCon'>
        <div className="Star"/>
        <div className='UtilTexCon'>
        <h2>NFT academy</h2>
        <p>Second, we offer NFT Academy. If you're looking for hands-on education about the
world's most exciting digital assets and the communities around them, this is the
place for you! The Love Llamas NFT Academy is our metaverse academy for
learning about the NFT landscape and what unique opportunities are out there for
you to explore.</p>
        </div>
        </div>
        </div>
    </div>
  )
}
