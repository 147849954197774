import React, { useRef } from "react";
import './style.css';
import { Routes, Switch, Route } from "react-router-dom";

import NavBar from "./components/navBar";
import Header from "./components/header";
import Mining from "./components/mining";
// import About from "./components/about";
import RoadMap from "./components/roadmap";
import FAQ from "./components/faq";
import Utilities from "./components/utilities";
import Team from "./components/team";
import Footer from "./components/footer";

function App() {

  return (
    <div className="App">
      <Routes>
      <Route path={"/"} element={<HomeScreen/>}/>
      </Routes>
    </div>
  );
}

function HomeScreen(){
  const head = useRef(null)
  const about = useRef(null)
  const mint = useRef(null)
  const faq = useRef(null)
  const road = useRef(null)
  const team = useRef(null)

  function onScroll(val) {
    if (val === "h") {
      window.scrollTo({
        top: head.current.offsetTop,
        behavior: "smooth"
      })
    } else if (val === "a") {
      window.scrollTo({
        top: about.current.offsetTop,
        behavior: "smooth"
      })
    } else if (val === "m") {
      window.scrollTo({
        top: mint.current.offsetTop,
        behavior: "smooth"
      })
    } else if (val === "f") {
      window.scrollTo({
        top: faq.current.offsetTop,
        behavior: "smooth"
      })
    }
    else if (val === "r") {
      window.scrollTo({
        top: road.current.offsetTop,
        behavior: "smooth"
      })
    }
    else if (val === "t") {
      window.scrollTo({
        top: team.current.offsetTop,
        behavior: "smooth"
      })
    }
  }

  return(
    <>
    <NavBar scrollTo={onScroll}/>
      <Header myRef={head}/>
      <Mining myRef={mint}/>
      {/* <About myRef={about}/> */}
      <RoadMap myRef={road}/>
      <Utilities myRef={about}/>
      <Team myRef={team}/>
      <FAQ myRef={faq}/>
      <Footer/>
      </>
  )
}

export default App;
